import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import button from '../../img/button.svg'

import Fade from "react-reveal/Fade"

import stars from '../../img/stars.png'

import 'swiper/css';

import feedback1 from '../../img/feedback1.webp'
import feedback2 from '../../img/feedback2.webp'
import feedback3 from '../../img/feedback3.webp'
import feedback4 from '../../img/feedback4.webp'
import feedback5 from '../../img/feedback5.webp'

import { useTranslation, Trans } from "react-i18next";

const feedbackData = [
    {
        id: 1,
        name: 'Sarah L., Energy Consultant',
        feedback: 'home.4',
        image: feedback1
    },
    {
        id: 2,
        name: 'John M., Procurement Manager',
        feedback: 'home.5',
        image: feedback2
    },
    {
        id: 3,
        name: 'Ahmed R., Trade Director',
        feedback: 'home.6',
        image: feedback3
    },
    {
        id: 4,
        name: 'Emma K., Industrial Operations Manager',
        feedback: 'home.7',
        image: feedback4
    },
    {
        id: 5,
        name: 'Liam T., CEO, Manufacturing Firm',
        feedback: 'home.8',
        image: feedback5
    },
];

function FeedBack({ currentLang }) {
    
  const [swiper, setSwiper] = useState({});
  
  const { t } = useTranslation();
  
    const [reloadKey, setReloadKey] = useState(0);

    useEffect(() => {
        setReloadKey(prev => prev + 1); // Обновляем ключ каждый раз при смене языка
    }, [currentLang]);

  return (
    <>
        <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-auto xl:pb-[160px] pb-[100px] relative overflow-hidden' key={reloadKey}>
            <div className='flex justify-between mx-[20px] xl:mx-0 items-center'>
                <div>
                    <div className='bg-black h-[1px]'></div>
                    <div className='flex items-start mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black mt-[10px] xl:mt-[15px]'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'><Trans>{t('new.about.11')}</Trans></h1>
                    </div>
                </div> 
                <div className={`flex gap-[20px] ${currentLang === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] xl:w-[39px] xl:h-[39px] rotate-180 cursor-pointer'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px] xl:w-[39px] xl:h-[39px] cursor-pointer'
                    />
                </div>               
            </div>
            <div>
            <Swiper
                modules={[Navigation, Autoplay]}
                slidesPerView={2}
                spaceBetween={20}
                loop={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                className='mt-[30px] xl:mt-[60px]'
                onInit={(e) => {
                    setSwiper(e);
                }}
                breakpoints={{
                    1: {
                        slidesPerView: 1,
                      },
                    680: {
                      slidesPerView: 2,
                    },
                  }}
            >
            {feedbackData.map((slide) => (
                <SwiperSlide key={slide.id}>
                    <div className='xl:w-[630px] h-[270px] border border-[#00000033] mx-[20px] xl:mx-0 p-[20px] xl:p-[40px]'>
                        <div className='flex items-end gap-[12px]'>
                            <img src={slide.image} alt=""/>
                            <div>
                                <img src={stars} alt="" />
                                <h1 className='inter text-[16px] xl:text-[20px] font-medium mt-[12px]'>{slide.name}</h1>
                            </div>
                        </div>
                        <p className='text-[14px] xl:text-[16px] textcolor mt-[12px]'>{t(slide.feedback)}</p>
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
            </div>
        </div>
    </>
  );
}

export default FeedBack;
