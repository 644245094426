import React from 'react';

import Fade from 'react-reveal/Fade'

import i1 from '../../img/Services/img1.webp'
import i2 from '../../img/Services/img2.webp'

import { useTranslation, Trans } from "react-i18next";

function Unmatched() {

  const { t } = useTranslation();

  return (
    <>
      <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto'>
        <div className='flex xl:flex-row flex-col justify-between'>
            <div>
                <div>
                  <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                  <div className='flex items-start mt-[13px] gap-[10px]'>
                    <div className='w-[4px] h-[4px] bg-black mt-[15px]'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] xl:max-w-[350px]'><Trans>{t('new.services.4')}</Trans></h1>
                  </div>
                </div>
                <p className='inter text-[14px] xl:text-[16px] font-light mt-[20px] xl:mt-[40px] xl:max-w-[457px] textcolor'>{t('new.services.5')}</p>
            </div>
            <Fade right><img src={i1} alt="" className='mx-auto xl:mx-0 mt-[20px] xl:mt-0 h-[325px] object-cover w-full xl:w-[663px]'/></Fade>
        </div>
        <div className='flex xl:flex-row flex-col-reverse xl:gap-[60px] items-end mt-[40px] xl:mt-[20px]'>
          <Fade left><img src={i2} alt="" className='mx-auto xl:mx-0 mt-[20px] xl:mt-0 h-[325px] object-cover w-full xl:w-[663px]'/></Fade>
          <div>
              <div>
                  <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                  <div className='flex items-center mt-[13px] gap-[10px]'>
                      <div className='w-[4px] h-[4px] bg-black'></div>
                      <h1 className='neutral text-[16px] xl:text-[24px]'>{t('new.services.6')}</h1>
                  </div>
              </div>
              <p className='inter text-[14px] xl:text-[16px] font-light mt-[20px] xl:mt-[40px] xl:max-w-[473px] textcolor'>{t('new.services.7')}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Unmatched;
