import React from 'react';

import phone from '../img/phone.svg';
import location from '../img/location.svg';
import emaill from '../img/email.svg';

import s1 from '../img/footer/s1.png';
import s2 from '../img/footer/s2.png';
import s3 from '../img/footer/s3.png';

import { useTranslation } from "react-i18next";

const { hostname } = document.location;

function Footer() {

  const { t } = useTranslation();

  return (
    <>
      <div className='max-w-[1280px] mx-auto'>
        <div className='flex md:flex-row justify-between flex-col gap-[60px] xl:gap-0 pb-[80px]'>
          <div className='mx-[20px] xl:mx-0'>
              <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
              <div className='flex items-center mt-[13px] gap-[10px]'>
                  <div className='w-[4px] h-[4px] bg-black'></div>
                  <h1 className='neutral text-[16px] xl:text-[24px]'>{t('contactform.10')}</h1>
              </div>
              <div className='flex mt-[20px]'>
                <img src={emaill} alt="" />
                <p className='mx-[20px] text-[14px] inter textcolor mt-[2px]'>{t('footer.8')}: info@{hostname}</p>
              </div>
              <a href="/contactform">
                <div className='buttoncars bg-black hover:!bg-[#D14507] transition-all mt-[20px]'>
                  <p className='text-white inter text-[16px] xl:text-[20px]'>{t('footer.1')}</p>
                </div>
              </a>
          </div>
          <div className='mx-[20px] xl:mx-0'>
              <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
              <div className='flex items-center mt-[13px] gap-[10px]'>
                  <div className='w-[4px] h-[4px] bg-black'></div>
                  <h1 className='neutral text-[16px] xl:text-[24px]'>{t('footer.add.1')}</h1>
              </div>
              <div className='flex justify-between md:flex-col gap-[12px] mt-[20px]'>
                <a href="https://www.youtube.com/"><div className='flex items-center gap-[20px]'>
                  <img src={s1} alt="" className='filter grayscale hover:grayscale-0 transition-all'/>
                  <p className='text-[#000000B2] text-[14px] inter'>Youtube</p>
                </div></a>
                <a href="https://linkedin.com/"><div className='flex items-center gap-[20px]'>
                  <img src={s2} alt="" className='filter grayscale hover:grayscale-0 transition-all'/>
                  <p className='text-[#000000B2] text-[14px] inter'>LinkedIn</p>
                </div></a>
                <a href="https://www.instagram.com/"><div className='flex items-center gap-[20px]'>
                  <img src={s3} alt="" className='filter grayscale hover:grayscale-0 transition-all'/>
                  <p className='text-[#000000B2] text-[14px] inter'>Instagram</p>
                </div></a>
              </div>
          </div>
          <div className='mx-[20px] xl:mx-0 xl:max-w-[392px]'>
            <p className='text-[#000000B2] text-[14px] inter font-[300]'>{t('footer.add.2')}</p>
            <p className='text-[#000000] text-[14px] inter mt-[15px] italic'>{t('footer.add.3')}</p>
            <p className='text-[#000000] text-[14px] inter mt-[15px]'>© {new Date().getFullYear()} {t('footer.add.4')}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
