import React from 'react';

import main from '../../img/About/mainnew.webp'
import mainm1 from '../../img/About/image.webp'
import mainm2 from '../../img/About/grp.webp'

import { Trans, useTranslation } from "react-i18next";

function Main({ currentLang }) {

  const { t } = useTranslation();

  return (
    <>
        <div className='h-[60px] xl:h-[80px]'></div>
        <div className='max-w-[1280px] mx-auto xl:h-[703px] relative overflow-hidden'>
          <img src={main} alt="" className={`hidden xl:block absolute w-full h-full object-cover  ${currentLang === 'ar' ? 'scale-x-[-1]' : ''}`}/>
          <div className='max-w-[1280px] mx-auto relative xl:mt-[65px]'>
            <img src={mainm1} className='px-[20px] xl:px-0 h-[180px] w-full object-cover block xl:hidden' alt="" />
            <p className='neutral text-black text-[24px] xl:text-[48px] relative mx-[20px] xl:mx-0 mt-[20px] xl:mt-0'><Trans>{t('new.about.1')}</Trans></p>
            <div className='flex flex-col sm:flex-row'>
              <div>
                <p className='inter xl:max-w-[549px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px] mx-[20px] xl:mx-0 xl:mt-[40px]'>{t('new.about.2')}</p>
                <div className={`xl:mt-[285px] ${currentLang === 'ar' ? 'xl:mr-[715px]' : 'xl:ml-[715px]'}`}>
                  <p className='inter xl:max-w-[339px] text-[#000000] text-[14px] xl:text-[16px] mt-[20px] mx-[20px] xl:mx-0 xl:mt-[40px]'>{t('new.about.3')}</p>
                  <a href="/contactform">
                    <div className="flex gap-[4px] group mt-[40px] mx-[20px] xl:mx-0">
                        <div className="buttoncars !bg-black !bg-opacity-100 group-hover:!bg-opacity-80 transition duration-300">
                            <p className="text-white inter text-[16px] xl:text-[20px]">{t('cars.3')}</p>
                        </div>
                        <div className="butcar flex items-center px-[20px]">
                            <svg
                                className={`transition-transform duration-300 ${currentLang === 'ar' ? 'group-hover:-translate-x-2 rotate-180' : 'group-hover:translate-x-2'}`}
                                width="18"
                                height="10"
                                viewBox="0 0 18 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.6903 5.41784C17.9211 5.18707 17.9211 4.81293 17.6903 4.58216L13.9298 0.821642C13.6991 0.590878 13.3249 0.590878 13.0941 0.821642C12.8634 1.05241 12.8634 1.42655 13.0941 1.65731L16.4368 5L13.0941 8.34269C12.8634 8.57345 12.8634 8.94759 13.0941 9.17836C13.3249 9.40912 13.6991 9.40912 13.9298 9.17836L17.6903 5.41784ZM0.727051 5.59091H17.2725V4.40909H0.727051V5.59091Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                  </a>
                </div>
              </div>
              <img src={mainm2} alt="" className='mt-[40px] sm:mt-0 xl:mt-[60px] xl:hidden block mx-[20px] xl:mx-0'/>
            </div>
          </div>
        </div>
    </>
  );
}

export default Main;
