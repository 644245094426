import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";

import Fade from "react-reveal/Fade";

function MobileMenu({ toggleMenu, isOpen, isScrolledPastHeight, MainMenu, currentLang}) {

    const location = useLocation();

    const { t } = useTranslation();

    const [isOpenServices, setIsOpenServices] = useState(false);
  
    const toggleServices = () => {
        setIsOpenServices(!isOpenServices);
    };

    useEffect(() => {
        setIsOpenServices(false);
    }, [location.pathname]);

    return (
        <div>
            <div className="relative z-[900] w-[30px] h-[15px] flex flex-col justify-between cursor-pointer" onClick={toggleMenu}>
                <div className={`w-full h-[1px] transition-all transform ${isOpen ? 'rotate-45 translate-y-[5px] !bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
                <div className={`w-full h-[1px]  transition-all transform ${isOpen ? '-rotate-45 -translate-y-[9px] !bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
            </div>
            <div className={`fixed top-0 bottom-0 w-full h-full md:w-1/2 xl:w-1/3 bg-white z-50 ltr:right-0 rtl:left-0 transition-all duration-500 transform ${isOpen ? '' : 'translate-x-[1000px] rtl:translate-x-[-1000px]'}`}>
                <div className="flex flex-col gap-[15px] mt-[100px] mx-[20px] md:mx-[40px] xl:mx-[60px]">
                    <Link to="/"><p className={`text-[24px] neutral ${location.pathname === '/' ? 'text-[#D14507]' : 'text-[#D14507'}`} onClick={toggleMenu}>{t('header.0')}</p></Link>
                    <div onClick={toggleServices} className="cursor-pointer flex ote, items-center gap-[10px]">
                        <p className="text-[24px] neutral">{t('header.1')}</p>
                        <svg className={`transition-all ${isOpenServices ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" width="19" height="9" viewBox="0 0 19 9" fill="none">
                            <path d="M1 1L9.75758 8L18 1" stroke="black" stroke-width="1.2"/>
                        </svg>
                    </div>
                    {isOpenServices && (
                        <Fade>
                            <div>
                                <Link to="/services"><p className={`text-[16px] inter ${location.pathname === '/services' ? 'text-[#D14507]' : 'text-[#000000B2]'}`} onClick={toggleMenu}>{t('header.add.1')}</p></Link>
                                <Link to="/cars"><p className={`text-[16px] inter ${location.pathname === '/cars' ? 'text-[#D14507]' : 'text-[#000000B2]'}`} onClick={toggleMenu}>{t('header.add.2')}</p></Link>
                                <Link to="/naturalres"><p className={`text-[16px] inter ${location.pathname === '/naturalres' ? 'text-[#D14507]' : 'text-[#000000B2]'}`} onClick={toggleMenu}>{t('header.add.3')}</p></Link>
                                <Link to="/textiles"><p className={`text-[16px] inter ${location.pathname === '/textiles' ? 'text-[#D14507]' : 'text-[#000000B2]'}`} onClick={toggleMenu}>{t('header.add.4')}</p></Link>
                                <Link to="/industrial"><p className={`text-[16px] inter ${location.pathname === '/industrial' ? 'text-[#D14507]' : 'text-[#000000B2]'}`} onClick={toggleMenu}>{t('header.add.5')}</p></Link>
                                <Link to="/investments"><p className={`text-[16px] inter ${location.pathname === '/investments' ? 'text-[#D14507]' : 'text-[#000000B2]'}`} onClick={toggleMenu}>{t('header.add.6')}</p></Link>
                            </div>
                        </Fade>
                    )}
                    <Link to="/contactform"><p className={`text-[24px] neutral ${location.pathname === '/contactform' ? 'text-[#D14507]' : 'text-[#D14507'}`} onClick={toggleMenu}>{t('header.4')}</p></Link>
                    <Link to="/about"><p className={`text-[24px] neutral ${location.pathname === '/about' ? 'text-[#D14507]' : 'text-[#D14507'}`} onClick={toggleMenu}>{t('header.2')}</p></Link>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;