import React from 'react';

import rec from '../../img/Home/rec.webp'

import 'swiper/css';

import { useTranslation, Trans } from "react-i18next";

function StayUpdated({ currentLang }) {

  const { t } = useTranslation();

  return (
    <>
        <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto'>
            <div className='bg-black h-[1px] xl:w-[305px]'></div>
            <div className='flex items-start mt-[13px] gap-[10px]'>
                <div className='w-[4px] h-[4px] bg-black mt-[10px] xl:mt-[15px]'></div>
                <h1 className='neutral text-[16px] xl:text-[24px] xl:max-w-[305px]'>{t('new.home.54')}</h1>
            </div>
            <div className='flex flex-col md:flex-row mt-[20px] xl:mt-[40px] gap-[20px]'>
                <div className='xl:w-1/2 h-[456px] border border-[#00000033] p-[20px] xl:p-[40px] flex flex-col justify-between'>
                    <img src={rec} className='h-[198px] w-full object-cover' alt="" />
                    <div className='flex gap-[20px]'>
                        <div className='w-[2px] bg-black'></div>
                        <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('new.home.55')}</p>
                    </div>
                    <p className='inter text-[14px] xl:text-[16px] font-[300] textcolor'>{t('new.home.56')}</p>
                </div>
                <div className='xl:w-1/2 flex flex-col gap-[20px]'>
                    <div className='h-[190px] xl:h-[218px] border border-[#00000033] flex flex-col justify-between p-[20px] xl:p-[40px]'>
                        <div className='flex gap-[20px]'>
                            <div className='w-[2px] bg-black'></div>
                            <p className={`inter text-[16px] xl:text-[20px] font-[500] ${currentLang === 'ar' ? 'xl:max-w-[200px]' : 'xl:max-w-[400px]'}`}>{t('new.home.57')}</p>
                        </div>
                        <p className='inter text-[14px] xl:text-[16px] font-[300] textcolor'>{t('new.home.58')}</p>
                    </div>
                    <div className='h-[140px] xl:h-[218px] border border-[#00000033] flex flex-col justify-between p-[20px] xl:p-[40px]'>
                        <div className='flex gap-[20px]'>
                            <div className='w-[2px] bg-black'></div>
                            <p className={`inter text-[16px] xl:text-[20px] font-[500] ${currentLang === 'ar' ? 'xl:max-w-[200px]' : 'xl:max-w-[300px]'}`}>{t('new.home.59')}</p>
                        </div>
                        <p className='inter text-[14px] xl:text-[16px] font-[300] textcolor'>{t('new.home.60')}</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default StayUpdated;
