import React, { useEffect } from 'react';

import Banner from '../components/Home/Banner/Banner';
import WhyChoose from '../components/Home/WhyChoose'
import WhatOffer from '../components/Home/WhatOffer'
import OurValues from '../components/Home/OurValues'
import FeedBack from '../components/Home/FeedBack'
import Mission from '../components/Home/Mission';
import Video from '../components/Home/Video';

import Lazyload from 'react-lazy-load'
import CompanyHighlights from '../components/Home/CompanyHighlights';
import GlobalPresence from '../components/Home/GlobalPresence';
import StayUpdated from '../components/Home/StayUpdated';

function Home({ currentLang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='relative overflow-hidden'>
      <Banner currentLang={currentLang} />
      <Mission/>
      <Video/>
      <OurValues currentLang={currentLang}/>
      <CompanyHighlights currentLang={currentLang}/>
      <GlobalPresence currentLang={currentLang}/>
      <WhyChoose currentLang={currentLang}/>
      <WhatOffer currentLang={currentLang}/>
      <StayUpdated currentLang={currentLang}/>
      <FeedBack currentLang={currentLang}/>   
    </div>
  );
}

export default Home;
