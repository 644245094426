import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import $ from "jquery";
import Cookies from "js-cookie";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { images } from "./imagesToLoad";
import AnimatedRoutes from "./AnimatedRoutes";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const storedLang = Cookies.get("i18next");
  const [currentLang, setCurrentLang] = useState(storedLang || "en");

  useEffect(() => {
    isLoading && $("body").addClass("ovf-hidden");
    Promise.all(
      images.map(
        (image) =>
          new Promise((resolve, reject) => {
            const newImage = new Image();
            newImage.src = image;
            newImage.onload = () => resolve(image);
            newImage.onerror = (err) => reject(err);
          })
      )
    )
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    !isLoading && $("body").removeClass("ovf-hidden");
    !isLoading && $("#loader").addClass("hide");
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        $("#loader").addClass("hide");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [currentLang]);

  return (
    <Router>
      <Header currentLang={currentLang} storedLang={storedLang} setCurrentLang={setCurrentLang} />
      <AnimatedRoutes currentLang={currentLang} />
      <Footer />
    </Router>
  );
}

export default App;
