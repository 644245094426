import React from 'react';

import value1 from '../../img/ourvalues1.svg'
import value2 from '../../img/ourvalues3.svg'
import value3 from '../../img/ourvalues2.svg'
import value4 from '../../img/ourvalues4.svg'

import Fade from 'react-reveal/Fade'

import { useTranslation, Trans } from "react-i18next";

function OurValues({ currentLang }) {

  const { t } = useTranslation();

  return (
    <>
      <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-auto'>
        <div className='flex xl:flex-row justify-between flex-col'>
          <div className='mx-[20px] xl:mx-0 flex flex-col justify-between'>
            <div>
              <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
              <div className='flex items-center mt-[13px] gap-[10px]'>
                  <div className='w-[4px] h-[4px] bg-black'></div>
                  <h1 className='neutral text-[16px] xl:text-[24px]'>{t('home.10')}</h1>
              </div>
              <p className='mt-[20px] xl:mt-[40px] inter text-[14px] xl:text-[20px] font-[500] xl:max-w-[365px]'>{t('new.home.8')}</p>
              <p className='mt-[20px] textcolor inter text-[14px]'><Trans>{t('new.home.9')}</Trans></p>
            </div>
            <a href="/contactform">
              <div className="flex gap-[4px] group">
                  <div className="buttoncars !bg-black !bg-opacity-100 group-hover:!bg-opacity-80 transition duration-300">
                      <p className="text-white inter text-[16px] xl:text-[20px]">{t('cars.3')}</p>
                  </div>
                  <div className="butcar flex items-center px-[20px]">
                      <svg
                          className={`transition-transform duration-300 ${currentLang === 'ar' ? 'group-hover:-translate-x-2 rotate-180' : 'group-hover:translate-x-2'}`}
                          width="18"
                          height="10"
                          viewBox="0 0 18 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                              d="M17.6903 5.41784C17.9211 5.18707 17.9211 4.81293 17.6903 4.58216L13.9298 0.821642C13.6991 0.590878 13.3249 0.590878 13.0941 0.821642C12.8634 1.05241 12.8634 1.42655 13.0941 1.65731L16.4368 5L13.0941 8.34269C12.8634 8.57345 12.8634 8.94759 13.0941 9.17836C13.3249 9.40912 13.6991 9.40912 13.9298 9.17836L17.6903 5.41784ZM0.727051 5.59091H17.2725V4.40909H0.727051V5.59091Z"
                              fill="white"
                          />
                      </svg>
                  </div>
              </div>
            </a>
          </div> 
          <div className='grid grid-cols-2 gap-[20px] mx-[20px] xl:mx-0 mt-[80px] xl:mt-0'>
            <Fade><div className='xl:w-[394px] h-[170px] xl:h-[229px] border'>
              <div className='mt-[20px] xl:mx-[40px] mx-[20px]'>
                <div className='flex items-center justify-between'>
                  <p className='font-light inter text-[20px] xl:text-[24px]'>01</p>
                  <img src={value1} alt="" className='w-[40px] xl:w-[60px] md:hidden block'/>
                </div>
                <div className='flex'>
                  <img src={value1} alt="" className='w-[40px] xl:w-[60px] mt-[45px] xl:mt-[85px] hidden md:block'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] md:mx-[36px]'>{t('home.11')}</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={300}><div className='xl:w-[394px] h-[170px] xl:h-[229px] border'>
              <div className='mt-[20px] xl:mx-[40px] mx-[20px]'>
                <div className='flex items-center justify-between'>
                  <p className='font-light inter text-[20px] xl:text-[24px]'>02</p>
                  <img src={value2} alt="" className='w-[40px] xl:w-[60px] md:hidden block'/>
                </div>
                <div className='flex'>
                  <img src={value2} alt="" className='w-[40px] xl:w-[60px] mt-[45px] xl:mt-[85px] hidden md:block'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] md:mx-[36px]'>{t('home.12')}</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={600}><div className='xl:w-[394px] h-[170px] xl:h-[229px] border'>
              <div className='mt-[20px] xl:mx-[40px] mx-[20px]'>
                <div className='flex items-center justify-between'>
                  <p className='font-light inter text-[20px] xl:text-[24px]'>03</p>
                  <img src={value3} alt="" className='w-[40px] xl:w-[60px] md:hidden block'/>
                </div>
                <div className='flex'>
                  <img src={value3} alt="" className='w-[40px] xl:w-[60px] mt-[45px] xl:mt-[85px] hidden md:block'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] md:mx-[36px]'>{t('home.13')}</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={900}><div className='xl:w-[394px] h-[170px] xl:h-[229px] border'>
              <div className='mt-[20px] xl:mx-[40px] mx-[20px]'>
                <div className='flex items-center justify-between'>
                  <p className='font-light inter text-[20px] xl:text-[24px]'>04</p>
                  <img src={value4} alt="" className='w-[40px] xl:w-[60px] md:hidden block'/>
                </div>
                <div className='flex'>
                  <img src={value4} alt="" className='w-[40px] xl:w-[60px] mt-[45px] xl:mt-[85px] hidden md:block'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] md:mx-[36px]'>{t('home.14')}</p>
                </div>
              </div>
            </div></Fade>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurValues;
