import React, { useEffect, useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import img from '../img/Investments/image.webp'

import imgmob from '../img/Investments/imagem.webp'

import global from '../img/Investments/imgd.webp'
import global2 from '../img/Investments/imgm.webp'

import what1 from '../img/Investments/o1.svg'
import what2 from '../img/Investments/o2.svg'

import why1 from '../img/Investments/i1.webp'
import why2 from '../img/Investments/i2.webp'
import why3 from '../img/Investments/i3.webp'

import check from '../img/Investments/check.webp'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import button from '../img/button.svg'

import 'swiper/css';

function Investments({ currentLang }) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [swiper, setSwiper] = useState({});

  const { t } = useTranslation();

  const [reloadKey, setReloadKey] = useState(0);

    useEffect(() => {
        setReloadKey(prev => prev + 1); // Обновляем ключ каждый раз при смене языка
    }, [currentLang]);

  return (
    <div className='pb-[80px] xl:pb-[160px] relative overflow-hidden'>
        <div className='h-[60px] xl:h-[80px]'></div>
        <div className='max-w-[1280px] mx-auto md:h-[306px] xl:h-[506px] relative overflow-hidden'>
            <img src={img} alt="" className='hidden md:block absolute w-full h-full object-cover'/>
            <div className={`md:w-[517px] bg-[#FFFFFFCC] h-full absolute hidden md:block ${currentLang === 'ar' ? 'right-0' : 'left-0'}`}></div>
            <div className='max-w-[1280px] mx-[20px] xl:mx-[40px] relative mt-[40px] md:mt-[75px] xl:mt-[120px]'>
                <p className='text-[24px] xl:text-[48px] neutral xl:max-w-[411px]'>{t('investments.1')}</p>
                <p className='md:max-w-[392px] inter text-[16px] xl:text-[20px] font-[400] text-[#000] mt-[12px]'>{t('investments.2')}</p>
                <p className='md:max-w-[392px] inter text-[14px] xl:text-[16px] font-[400] text-[#000000B2] mt-[20px] xl:mt-[40px]'>{t('investments.3')}</p>
                <img src={imgmob} alt="" className='md:hidden block w-full mt-[40px] object-cover h-[280px]'/>
            </div>
        </div>
        <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between'>
            <div>
              <div className='bg-black h-[1px]'></div>
              <div className='flex items-center mt-[13px] gap-[10px]'>
                  <div className='w-[4px] h-[4px] bg-black'></div>
                  <h1 className='neutral text-[16px] xl:text-[24px]'>{t('investments.1')}</h1>
              </div>
            </div>
            <div className='mt-[20px] xl:mt-0'>
              <p className='xl:max-w-[807px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('investments.4')}</p>
            </div>
        </div>
        <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between'>
            <div className='flex flex-col'>
              <div className='bg-black h-[1px] flex-shrink-0'></div>
              <div className='flex mt-[13px] gap-[10px]'>
                  <div className='w-[4px] h-[4px] mt-[15px] bg-black'></div>
                  <h1 className='neutral text-[16px] xl:text-[24px]'><Trans>{t('investments.5')}</Trans></h1>
              </div>
            </div>
            <div className='mt-[40px] xl:mt-0'>
              <Fade bottom><div className='flex flex-col xl:flex-row xl:max-w-[807px]'>
                  <div className='flex-shrink-0'>
                      <img src={what1} className="w-[60px] h-[60px] xl:w-[80px] xl:h-[80px]" alt="" />
                  </div>
                  <div className='xl:mx-[60px] mt-[20px] xl:mt-0'>
                      <h1 className='inter text-[16px] xl:text-[20px]'>{t('investments.6')}</h1>
                      <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>{t('investments.7')}</p>
                  </div>
              </div></Fade>
              <div className='w-full colorline h-[1px] mt-[20px] xl:mt-[40px]'></div>
              <Fade bottom delay={300}><div className='flex flex-col xl:flex-row mt-[20px] xl:mt-[40px] xl:max-w-[807px]'>
                  <div className='flex-shrink-0'>
                      <img src={what2} className="w-[60px] h-[60px] xl:w-[80px] xl:h-[80px]" alt="" />
                  </div>
                  <div className='xl:mx-[60px] mt-[20px] xl:mt-0'>
                      <h1 className='inter text-[16px] xl:text-[20px]'>{t('investments.8')}</h1>
                      <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>{t('investments.9')}</p>
                  </div>
              </div></Fade>
          </div>
        </div>
        <div className='relative mt-[100px] xl:mt-[160px]'>
            <div className='max-w-[1280px] mx-auto md:h-[306px] xl:h-[506px] relative overflow-hidden mt-[100px] xl:mt-[160px]'>
                <img src={global} alt="" className='hidden md:block absolute w-full h-full object-cover'/>
                <div className={`md:w-[517px] bg-[#FFFFFFCC] h-full absolute hidden md:block ${currentLang === 'ar' ? 'right-0' : 'left-0'}`}></div>
                <div className='max-w-[1280px] mx-[20px] xl:mx-[40px] relative sm:mt-[20px] xl:mt-[80px]'>
                    <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[330px]'></div>
                    <div className='flex items-center mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'>{t('investments01')}</h1>
                    </div>
                    </div>
                    <p className='md:max-w-[392px] inter text-[14px] xl:text-[16px] font-[400] text-[#000000B2] mt-[20px] xl:mt-[40px]'>{t('investments02')}</p>
                    <p className='md:max-w-[392px] inter text-[14px] xl:text-[16px] font-[400] text-[#000000B2] mt-[20px]'>{t('investments03')}</p>
                    <img src={global2} alt="" className='md:hidden block w-full mt-[40px] object-cover h-[280px]'/>
                </div>
            </div>
        </div>
        <div className='max-w-[1280px] mx-auto mt-[100px] xl:mt-[160px]' key={reloadKey}>
        <div className='flex xl:block justify-between mx-[20px] xl:mx-0 items-center'>
                <div className='flex flex-col xl:flex-row justify-between'>
                    <div>
                        <div className='bg-black h-[1px]'></div>
                        <div className='flex mt-[13px] gap-[10px]'>
                            <div className='w-[4px] mt-[15px] h-[4px] bg-black'></div>
                            <h1 className='neutral text-[16px] xl:text-[24px]'><Trans>{t('investments.10')}</Trans></h1>
                        </div>
                    </div>
                    <p className='text-[16px] xl:text-[20px] inter xl:max-w-[800px] mt-[20px] xl:mt-0 hidden xl:block'>{t('investments.11')}</p>
                </div>
                <div className={`flex gap-[20px] xl:hidden flex-shrink-0 ${currentLang === 'ar' ? 'flex-row-reverse' : 'flex-row mr-[20px]'} w-[60px]`}>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180 cursor-pointer'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px] cursor-pointer'
                    />
                </div>               
              </div> 
            
            <div className='mt-[60px] gap-[20px] hidden xl:flex'>
                <div class='relative w-full h-[339px]'>
                    <img src={why3} alt="" class='absolute w-full h-full object-cover'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>01</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('investments.12')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-[#00000033]">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>01</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('investments.12')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('investments.15')}</p>
                    </div>
                </div>
                <div class='relative w-full h-[339px]'>
                    <img src={why2} alt="" class='absolute w-full h-full object-cover'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>02</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('investments.13')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-[#00000033]">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>02</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('investments.13')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('natural.16')}</p>
                    </div>
                </div>
                <div class='relative w-full h-[339px]'>
                    <img src={why1} alt="" class='absolute w-full h-full object-cover'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>03</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('investments.14')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-[#00000033]">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>03</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('investments.14')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('natural.17')}</p>
                    </div>
                </div>
            </div>
            <div className='mt-[40px] block xl:hidden w-full'>
                <Swiper
                    modules={[Navigation, Autoplay]}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    className='mt-[40px]'
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    breakpoints={{
                        768: { // Указываем ширину экрана (в пикселях)
                            slidesPerView: 2, // Количество слайдов на планшетах
                        },
                    }}
                >
                    <SwiperSlide>
                        <div class="mx-[20px] h-[180px] bg-white border border-[#00000033]">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium'>{t('investments.12')}</p>
                            <p className='textcolor text-[14px] font-light mx-[20px] mt-[12px]'>{t('investments.15')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="mx-[20px] h-[180px] bg-white border border-[#00000033]">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium'>{t('investments.13')}</p>
                            <p className='textcolor text-[14px] font-light mx-[20px] mt-[12px]'>{t('investments.16')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="mx-[20px] h-[180px] bg-white border border-[#00000033]">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium'>{t('investments.14')}</p>
                            <p className='textcolor text-[14px] font-light mx-[20px] mt-[12px]'>{t('investments.17')}</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <p className='text-[16px] xl:text-[20px] inter xl:max-w-[830px] mt-[20px] xl:mt-0 xl:hidden mx-[20px] xl:mx-0 block'>{t('investments.11')}</p>
            </div>
        </div>
        <div className='max-w-[1280px] mx-auto mt-[100px] xl:mt-[160px]'>
            <div className='mx-[20px] xl:mx-0 flex flex-col xl:flex-row justify-between'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                    <div className='flex mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black mt-[10px] xl:mt-[15px]'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'><Trans>{t('investments.18')}</Trans></h1>
                    </div>
                </div>
                <p className='text-[16px] xl:text-[20px] inter xl:max-w-[830px] mt-[20px] xl:mt-0'>{t('investments.19')}</p>
            </div>
            <div className='grid grid-cols-1 xl:grid-cols-2 gap-x-[20px] mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-0'>
                <div className='flex gap-[12px] sm:gap-[50px] xl:gap-[70px] items-start border-b py-[10px] md:py-[30px]'>
                    <img src={check} className='flex-shrink-0 sm:w-[34px] sm:h-[34px] w-[20px] h-[20px] mt-[3px] sm:mt-0' alt="" />
                    <div>
                        <p className='text-[16px] xl:text-[20px] inter font-[500]'>{t('investments.20')}</p>
                        <p className='text-[14px] xl:text-[16px] textcolor inter font-[300] mt-[12px]'>{t('investments.21')}</p>
                    </div>
                </div>
                <div className='flex gap-[12px] sm:gap-[50px] xl:gap-[70px] items-start border-b py-[10px] md:py-[30px]'>
                    <img src={check} className='flex-shrink-0 sm:w-[34px] sm:h-[34px] w-[20px] h-[20px] mt-[3px] sm:mt-0' alt="" />
                    <div>
                        <p className='text-[16px] xl:text-[20px] inter font-[500]'>{t('investments.22')}</p>
                        <p className='text-[14px] xl:text-[16px] textcolor inter font-[300] mt-[12px]'>{t('investments.23')}</p>
                    </div>
                </div>
                <div className='flex gap-[12px] sm:gap-[50px] xl:gap-[70px] items-start pt-[10px] md:pt-[30px] pb-[10px] md:pb-[30px] xl:pb-0 border-b xl:border-none'>
                    <img src={check} className='flex-shrink-0 sm:w-[34px] sm:h-[34px] w-[20px] h-[20px] mt-[3px] sm:mt-0' alt="" />
                    <div>
                        <p className='text-[16px] xl:text-[20px] inter font-[500]'>{t('investments.24')}</p>
                        <p className='text-[14px] xl:text-[16px] textcolor inter font-[300] mt-[12px]'>{t('investments.25')}</p>
                    </div>
                </div>
                <div className='flex gap-[12px] sm:gap-[50px] xl:gap-[70px] items-start pt-[10px] md:pt-[30px]'>
                    <img src={check} className='flex-shrink-0 sm:w-[34px] sm:h-[34px] w-[20px] h-[20px] mt-[3px] sm:mt-0' alt="" />
                    <div>
                        <p className='text-[16px] xl:text-[20px] inter font-[500]'>{t('investments.26')}</p>
                        <p className='text-[14px] xl:text-[16px] textcolor inter font-[300] mt-[12px]'>{t('investments.27')}</p>
                    </div>
                </div>
            </div>
        </div>
    </div> 
  );
}

export default Investments;
