import React, { useState, useEffect, useRef } from 'react';

import Fade from 'react-reveal/Fade'
import Rotate from 'react-reveal/Rotate'

import circle from '../../img/Home/circle.webp'

import { useTranslation, Trans } from "react-i18next";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from "recharts";

const data01 = [
  { year: 2022, partners: 80 },
  { year: 2023, partners: 100 },
  { year: 2024, partners: 120 },
];

const data02 = [
  { year: 2022, Locations: 4 },
  { year: 2023, Locations: 4 },
  { year: 2024, Locations: 5 },
];

const data03 = [
  { year: 2022, Projects: 40 },
  { year: 2023, Projects: 50 },
  { year: 2024, Projects: 65 },
];

const data2 = [
  { year: 2020, value1: 3.8, value2: 4.1 },
  { year: 2021, value1: 4.0, value2: 4.2 },
  { year: 2022, value1: 4.3, value2: 4.5 },
  { year: 2023, value1: 4.5, value2: 4.8 },
  { year: 2024, value1: 4.7, value2: 5.0 },
];

function CompanyHighlights({ currentLang }) {

  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);
  const [num1, setnum1] = useState(0);
  const [num2, setnum2] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const circleRef = useRef(null);

  const [activeline, setActiveline] = useState(1);

  // Функция для проверки видимости элемента
  const checkVisibility = () => {
    const rect = circleRef.current.getBoundingClientRect();
    const isInViewport =
      rect.top >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth;
    setIsVisible(isInViewport);
  };

  useEffect(() => {
    // Проверка видимости элемента при прокрутке и изменении размеров
    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);

    // Убираем слушатели при размонтировании
    return () => {
      window.removeEventListener('scroll', checkVisibility);
      window.removeEventListener('resize', checkVisibility);
    };
  }, []);


  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        if (progress < 97) {
          setProgress((prev) => prev + 1);
        }
      }, 20);

      return () => clearInterval(interval); // Очистка интервала при размонтировании
    }
  }, [isVisible, progress]);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        if (num1 < 100) {
          setnum1((prev) => prev + 1);
        }
      }, 20);

      return () => clearInterval(interval); // Очистка интервала при размонтировании
    }
  }, [isVisible, num1]);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        if (num2 < 5) {
          setnum2((prev) => prev + 1);
        }
      }, 200);

      return () => clearInterval(interval); // Очистка интервала при размонтировании
    }
  }, [isVisible, num2]);
  

  return (
    <>
      <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto'>
        <div className='bg-black h-[1px] w-[178px] xl:w-[305px]'></div>
        <div className='flex items-center mt-[13px] gap-[10px]'>
            <div className='w-[4px] h-[4px] bg-black'></div>
            <h1 className='neutral text-[16px] xl:text-[24px]'>{t('new.home.10')}</h1>
        </div>
        <div className='mt-[20px] xl:mt-[40px]'>
            <div className='flex flex-col xl:flex-row gap-[20px]'>
                <div className='bg-[#F0F0F0] h-[132px] xl:w-1/3 flex items-center justify-between xl:pl-[5px] pr-[20px] gap-[20px] xl:gap-[25px]'>
                    <div className='flex items-center'>
                        <div className="circle-container">
                            <svg className="progress-circle w-[80px] xl:w-[100px]" viewBox="0 0 120 120" ref={circleRef}>
                                <circle
                                className="progress-background"
                                cx="60"
                                cy="60"
                                r="54"
                                strokeWidth="12"
                                />
                                <circle
                                className="progress-bar"
                                cx="60"
                                cy="60"
                                r="54"
                                strokeWidth="12"
                                strokeDasharray="339.292" /* 2 * π * r */
                                strokeDashoffset={(1 - progress / 110) * 339.292}
                                />
                            </svg>
                            <span className="absolute neutral text-[20px] text-[#D14507]">{progress}%</span>
                        </div>
                        <p className='inter text-[12px] xl:text-[14px] text-[#000000B2] uppercase md:normal-case md:text-[#D14507] ml-[-12px] mr-[-8px] md:mr-0 md:ml-0'><Trans>{t('new.home.11')}</Trans></p>
                    </div>
                    <div className='flex gap-[20px] items-center'>
                        <div className='flex flex-col gap-[7px]'>
                            <Fade cascade duration={2000}><div className='w-[20px] xl:w-[40px] h-[11px] bg-[#9D3305]'></div>
                            <div className='w-[20px] xl:w-[40px] h-[11px] bg-[#D14507]'></div>
                            <div className='w-[20px] xl:w-[40px] h-[11px] bg-[#E4723F]'></div>
                            <div className='w-[20px] xl:w-[40px] h-[11px] bg-[#EA9772]'></div>
                            <div className='w-[20px] xl:w-[40px] h-[11px] bg-[#F8B99E]'></div></Fade>
                        </div>
                        <div>
                            
                            <p className='inter text-[12px] xl:text-[14px] text-[#000000B2] uppercase md:normal-case md:text-[#D14507]'><span className='neutral text-[#D14507] text-[20px]'>{num2} </span><Trans>{t('new.home.12')}</Trans></p>
                        </div>
                    </div>
                </div>
                <div className={`bg-[#F0F0F0] py-[10px] md:py-0 md:h-[132px] xl:w-2/3 flex flex-col md:flex-row ${currentLang === 'ar' ? 'pr-[20px]' : 'pl-[20px]'} md:items-center justify-center gap-[20px]`}>
                    <div className='flex items-center gap-[18px]'>
                        <p className='text-[#D14507] neutral text-[30px] xl:text-[40px]'>{num1}+</p>
                        <p className='inter text-[#000000B2] text-[12px] xl:text-[14px] uppercase md:normal-case'><Trans>{t('new.home.13')}</Trans></p>
                    </div>
                    <div className={`h-px w-[calc(100%-20px)] md:w-px md:h-[70px] bg-black`}></div>
                    <div className='flex items-center gap-[18px]'>
                        <p className='text-[#D14507] neutral text-[30px] xl:text-[40px]'>{num2}К</p>
                        <p className='inter text-[#000000B2] text-[12px] xl:text-[14px] ml-[35px] md:ml-0 uppercase md:normal-case'><Trans>{t('new.home.14')}</Trans></p>
                    </div>
                    <div className={`h-px w-[calc(100%-20px)] md:w-px md:h-[70px] bg-black`}></div>
                    <div className='flex items-center gap-[18px]'>
                        <p className='text-[#D14507] neutral text-[30px] xl:text-[40px]'>{num2}+</p>
                        <p className='inter text-[#000000B2] text-[12px] xl:text-[14px] ml-[40px] md:ml-0 uppercase md:normal-case'><Trans>{t('new.home.15')}</Trans></p>
                    </div>
                </div>
            </div>
        </div>
        <div className='flex flex-col xl:flex-row gap-[20px] mt-[20px]'>
            <div className='xl:w-1/3 flex flex-col gap-[20px]'>
                <div className='bg-[#F0F0F0] h-[275px] p-[20px]'>
                    <div className='flex items-center gap-[15px]'>
                        <div className='w-[2px] h-[24px] bg-black'></div>
                        <p className='inter text-[14px] font-[500] text-black'>{t('new.home.16')}</p>
                    </div>
                    <div className='mt-[20px]'>
                      <ResponsiveContainer width="100%" height={210}>
                        <BarChart data={data2}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="year" />
                          <Bar dataKey="value1" fill="#EA9772" barSize={20} animationDuration={1000} />
                          <Bar dataKey="value2" fill="#D14507" barSize={20} animationDuration={1200} />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                </div>
                <div className='bg-[#F0F0F0] h-[275px] p-[20px]'>
                    <div className='flex items-center gap-[15px]'>
                        <div className='w-[2px] h-[24px] bg-black'></div>
                        <p className='inter text-[14px] font-[500] text-black'>{t('new.home.17')}</p>
                    </div>
                    <div className='mt-[20px] flex items-end gap-[15px]'>
                        <Rotate><img className='w-[140px] sm:w-[187px]' src={circle} alt="" /></Rotate>
                        <div className='flex flex-col gap-[6px]'>
                            <div className={`border-[1px] border-[#00000033] rounded-[20px] flex items-center gap-[9px] px-[15px] py-[6px]`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="4" cy="4" r="4" fill="#9D3405"/>
                                </svg>
                                <p className='inter text-[13px] font-[500] text-[#959595]'>{t('new.home.19')}</p>
                            </div>
                            <div className='border-[1px] border-[#00000033] rounded-[20px] flex items-center gap-[9px] px-[15px] py-[6px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="4" cy="4" r="4" fill="#D14507"/>
                                </svg>
                                <p className='inter text-[13px] font-[500] text-[#959595]'>{t('new.home.20')}</p>
                            </div>
                            <div className='border-[1px] border-[#00000033] rounded-[20px] flex items-center gap-[9px] px-[15px] py-[6px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="4" cy="4" r="4" fill="#E4723F"/>
                                </svg>
                                <p className='inter text-[13px] font-[500] text-[#959595]'>{t('new.home.21')}</p>
                            </div>
                            <div className='border-[1px] border-[#00000033] rounded-[20px] flex items-center gap-[9px] px-[15px] py-[6px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="4" cy="4" r="4" fill="#EA9772"/>
                                </svg>
                                <p className='inter text-[13px] font-[500] text-[#959595]'>{t('new.home.22')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='xl:w-2/3 bg-[#F0F0F0] md:h-[570px] p-[20px]'>
                <div className='flex items-center gap-[15px]'>
                    <div className='w-[2px] h-[24px] bg-black'></div>
                    <p className='inter text-[14px] font-[500] text-black'>{t('new.home.18')}</p>
                </div>
                <div className='mt-[20px] flex relative' dir="ltr">
                  {activeline === 1 ? 
                  <>
                  <p className={`${currentLang === 'ar' ? 'left-[-20px]' : currentLang === 'tr' ? 'left-[-30px]' : 'left-[-60px]'} -rotate-90 absolute top-[100px] md:top-[200px] inter text-[14px] font-[500] text-[#959595]`}>{t('new.home.27')}</p>
                  <ResponsiveContainer width="100%" height={window.innerWidth < 768 ? 250 : 450}>
                    <LineChart data={data01}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" padding={{ left: 20 , right: 20}}/>
                      <YAxis domain={[70, 120]} padding={{ top: 20,}}/>
                      <Tooltip />
                      <Line type="monotone" dataKey="partners" stroke="#D14507" strokeWidth={3} dot={{ r: 5 }} animationDuration={3000} />
                    </LineChart>
                  </ResponsiveContainer></> : null}
                  {activeline === 0 ? 
                   <>
                   <p className={`${currentLang === 'ar' ? 'left-[-20px]' : currentLang === 'tr' ? 'left-[-30px]' : 'left-[-60px]'} -rotate-90 absolute top-[100px] md:top-[200px] inter text-[14px] font-[500] text-[#959595]`}>{t('new.home.23')}</p>
                    <ResponsiveContainer width="100%" height={window.innerWidth < 768 ? 250 : 450}>
                      <LineChart data={data02}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" padding={{ left: 20 }} />
                        <YAxis domain={[3.5, 6]} />
                        <Tooltip />
                        <Line type="monotone" dataKey="Locations" stroke="#E1B01E" strokeWidth={3} dot={{ r: 5 }} animationDuration={3000} />
                      </LineChart>
                  </ResponsiveContainer></> : null}
                  {activeline === 2 ? 
                  <>
                  <p className={`${currentLang === 'ar' ? 'left-[-20px]' : currentLang === 'tr' ? 'left-[-30px]' : 'left-[-60px]'} -rotate-90 absolute top-[100px] md:top-[200px] inter text-[14px] font-[500] text-[#959595]`}>{t('new.home.28')}</p>
                  <ResponsiveContainer width="100%" height={window.innerWidth < 768 ? 250 : 450}>
                    <LineChart data={data03}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" padding={{ left: 20 }} />
                      <YAxis domain={[35, 70]} />
                      <Tooltip />
                      <Line type="monotone" dataKey="Projects" stroke="#3BA43F" strokeWidth={3} dot={{ r: 5 }} animationDuration={3000} />
                    </LineChart>
                  </ResponsiveContainer></> : null}
                </div>
                <div className='flex flex-col md:flex-row gap-[12px] justify-center mt-[20px] md:mt-0'>
                  <div onClick={() => setActiveline(0)} className={`border-[1px] transition-all ${activeline === 0 ? 'border-black' : 'border-[#00000033]'}  rounded-[20px] flex items-center gap-[9px] px-[15px] py-[3px] cursor-pointer`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="8" viewBox="0 0 30 8" fill="none">
                        <circle cx="15" cy="4" r="4" fill="#E1B01E"/>
                        <path d="M0 4H29.5" stroke="#E1B01E" stroke-width="2"/>
                      </svg>
                      <p className={`inter text-[13px] font-[500] transition-all ${activeline === 0 ? 'text-black' : 'text-[#959595] '}`}>{t('new.home.24')}</p>
                  </div>
                  <div onClick={() => setActiveline(1)} className={`border-[1px] transition-all ${activeline === 1 ? 'border-black' : 'border-[#00000033]'}  rounded-[20px] flex items-center gap-[9px] px-[15px] py-[3px] cursor-pointer`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="8" viewBox="0 0 30 8" fill="none">
                        <circle cx="15" cy="4" r="4" fill="#D14507"/>
                        <path d="M0 4H29.5" stroke="#D14507" stroke-width="2"/>
                      </svg>
                      <p className={`inter text-[13px] font-[500] transition-all ${activeline === 1 ? 'text-black' : 'text-[#959595] '}`}>{t('new.home.25')}</p>
                  </div>
                  <div onClick={() => setActiveline(2)} className={`border-[1px] transition-all ${activeline === 2 ? 'border-black' : 'border-[#00000033]'}  rounded-[20px] flex items-center gap-[9px] px-[15px] py-[3px] cursor-pointer`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="8" viewBox="0 0 30 8" fill="none">
                        <circle cx="15" cy="4" r="4" fill="#3BA43F"/>
                        <path d="M0 4H29.5" stroke="#3BA43F" stroke-width="2"/>
                      </svg>
                      <p className={`inter text-[13px] font-[500] transition-all ${activeline === 2 ? 'text-black' : 'text-[#959595] '}`}>{t('new.home.26')}</p>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default CompanyHighlights;
