import React, { useEffect } from 'react';

import Main from '../components/Services/Main'
import WhatWeOffer from '../components/Services/WhatWeOffer';
import WhyPartner from '../components/Services/WhyPartner';
import OurProducts from '../components/Services/OurProducts';
import Global from '../components/Services/Global';
import Advantages from '../components/Services/Advantages';
import Unmatched from '../components/Services/Unmatched';

function Services( { currentLang } ) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='relative overflow-hidden'>
      <Main currentLang={currentLang}/>
      <Unmatched/>
      <WhatWeOffer currentLang={currentLang}/>
      <WhyPartner/>
      <OurProducts currentLang={currentLang}/>
      <Global currentLang={currentLang}/>
      <Advantages currentLang={currentLang}/>
    </div>
  );
}

export default Services;
