import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import classes from './Banner.module.scss';
import './pagination.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-creative';

import image_01 from '../../../img/Home/Banner/image_01.webp';
import image_02 from '../../../img/Home/Banner/image_02.webp';
import image_03 from '../../../img/Home/Banner/image_03.webp';
import image_04 from '../../../img/Home/Banner/image_04.webp';
import image_05 from '../../../img/Home/Banner/image_05.webp';
import wave_top from '../../../img/Home/Banner/wave_top.svg';
import wave_bottom from '../../../img/Home/Banner/wave_bottom.svg';

const slides = [
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
];

const Banner = ({ currentLang }) => {
    const { t } = useTranslation();
    const [domLoaded, setDomLoaded] = useState(false);
    // eslint-disable-next-line
    const [swiper, setSwiper] = useState({});
    const [active, setActive] = useState(0);

    const scrollToVideo = () => {
        const element = document.getElementById('video-block-home');
        const offset = 150;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    const onSlideChange = () => {
        const element = document.getElementById("home-banner-wave");
        
        if (element) {
            element.classList.remove(`${classes.inactive}`);
            element.classList.add(`${classes.active}`);
    
            setTimeout(() => {
                element.classList.add(`${classes.inactive}`);
                element.classList.remove(`${classes.active}`);
            }, 3000);
        }
    }

    useEffect(() => {
        if (domLoaded) {
            onSlideChange();
        }
        // eslint-disable-next-line
    }, [active]);

    useEffect(() => {
        setDomLoaded(true);
    }, [])

    return (
        <section className={classes.wrap}>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto home-banner">
                <div className={classes.body}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} neutral text-[22px] xl:text-[48px]`}>
                            <Trans>
                                {t('new.home.1')}
                            </Trans>
                        </h1>
                        <div className={`${classes.text} inter text-[12px] xl:text-[14px] font-light`}>
                            <p>
                                {t('new.home.2')}
                            </p>
                            <p>
                                {t('new.home.3')}
                            </p>
                        </div>
                        <div className={`${classes.buttonWrap} flex items-center justify-center`}>
                            <div onClick={scrollToVideo} className={`${classes.button} flex items-center justify-center`}>
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M22.2893 13.3539C22.2946 13.7561 22.2109 14.1545 22.0443 14.5206C21.8693 14.9079 21.601 15.2439 21.2626 15.5006L10.0043 24.2622C9.60797 24.5644 9.13531 24.7503 8.6393 24.7989H8.37097C7.96542 24.7991 7.56561 24.7031 7.2043 24.5189C6.76588 24.3036 6.39487 23.9723 6.13158 23.5609C5.8683 23.1495 5.72282 22.6739 5.71097 22.1856V5.85225C5.70855 5.37012 5.84198 4.89706 6.09597 4.48725C6.34176 4.07747 6.69384 3.74183 7.1149 3.5159C7.53595 3.28997 8.01029 3.18217 8.48764 3.20392C8.9683 3.22725 9.43497 3.37658 9.84097 3.63558L21.111 11.1489C21.454 11.3869 21.741 11.6973 21.951 12.0589C22.1726 12.4556 22.2893 12.9001 22.2893 13.3539Z" fill="white" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className={classes.swiperWrap}>
                        <Swiper
                            modules={[Autoplay, Pagination]}
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={true}
                            direction={'vertical'}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false,
                            }}
                            speed={2000}
                            allowTouchMove={false}
                            simulateTouch={false}
                            keyboard={{ enabled: false }}
                            onInit={(e) => { setSwiper(e) }}
                            onSlideChange={(e) => setActive(e.activeIndex)}
                            className={classes.swiper}
                        >
                            {slides.map((slide, index) => (
                                <SwiperSlide key={index} className={`${classes.slide} ${index === active ? classes.active : ''}`}>
                                    <div className={classes.slideContent}>
                                        <img className={classes.image} src={slide} alt='' />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div id="home-banner-wave" className={`${classes.wave} ${classes.inactive}`}>
                        <img className={classes.top} src={wave_top} alt='' />
                        <img className={classes.bottom} src={wave_bottom} alt='' />
                    </div>
                    <div className={classes.discoverBtn}>
                        <a href="/contactform">
                            <div className="flex gap-[4px] group">
                                <div className="buttoncars !bg-black !bg-opacity-100 group-hover:!bg-opacity-80 transition duration-300">
                                    <p className="text-white inter text-[16px] xl:text-[20px]">{t('cars.3')}</p>
                                </div>
                                <div className="butcar flex items-center px-[20px]">
                                    <svg
                                        className={`transition-transform duration-300 ${currentLang === 'ar' ? 'group-hover:-translate-x-2 rotate-180' : 'group-hover:translate-x-2'}`}
                                        width="18"
                                        height="10"
                                        viewBox="0 0 18 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.6903 5.41784C17.9211 5.18707 17.9211 4.81293 17.6903 4.58216L13.9298 0.821642C13.6991 0.590878 13.3249 0.590878 13.0941 0.821642C12.8634 1.05241 12.8634 1.42655 13.0941 1.65731L16.4368 5L13.0941 8.34269C12.8634 8.57345 12.8634 8.94759 13.0941 9.17836C13.3249 9.40912 13.6991 9.40912 13.9298 9.17836L17.6903 5.41784ZM0.727051 5.59091H17.2725V4.40909H0.727051V5.59091Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
