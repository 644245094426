import React, { useState, useEffect } from 'react';

import whatoffer1 from '../../img/whatoffer1.webp'
import whatoffer2 from '../../img/whatoffer7.webp'
import whatoffer3 from '../../img/whatoffer9.webp'
import whatoffer4 from '../../img/whatoffer8.webp'
import whatoffer5 from '../../img/whatoffer6.webp'
import whatoffer6 from '../../img/whatoffer5.webp'
import whatoffer7 from '../../img/whatoffer10.webp'
import whatoffer8 from '../../img/whatoffer11.webp'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import Fade from "react-reveal/Fade"

import 'swiper/css';

import button from '../../img/button.svg'

import { useTranslation, Trans } from "react-i18next";

function WhatOffer({ currentLang }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);

  const [swiper, setSwiper] = useState({});

  const [currentIndex, setCurrentIndex] = useState(0);

  const { t } = useTranslation();

      const [reloadKey, setReloadKey] = useState(0);
  
      useEffect(() => {
          setReloadKey(prev => prev + 1); // Обновляем ключ каждый раз при смене языка
      }, [currentLang]);

  return (
      <>
        <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-auto relative overflow-hidden' key={reloadKey}>
        <div className='flex justify-between mx-[20px] xl:mx-0 items-center'>
                <div>
                    <div className='bg-black h-[1px]'></div>
                    <div className='flex items-start mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black mt-[10px] xl:mt-[15px]'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'><Trans>{t('home.22')}</Trans></h1>
                    </div>
                </div> 
                <div className={`flex gap-[20px] xl:hidden ${currentLang === 'ar' ? 'flex-row-reverse' : 'flex-row mr-[20px]'} w-[60px]`}>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180 cursor-pointer'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px] cursor-pointer'
                    />
                </div>               
            </div>            
            <div className='mt-[60px] xl:flex hidden gap-[20px]'>
                <div className='w-[630px]'>
                    <Fade><div className="relative">
                        <div
                        className="relative overflow-hidden"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        >
                        <img className={`w-full h-full object-cover ${isHovered ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer1} alt="" />
                        <div className={`absolute left-0 top-[216px] w-[433px] h-auto flex bg-white ${isHovered ? 'fade-in show' : 'fade-in'}`}>
                            <div className='mx-[40px]'>
                            <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.23')}</h1>
                            <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.24')}</p>
                            </div>
                        </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px] relative'>
                        <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered1(true)}
                            onMouseLeave={() => setIsHovered1(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered1 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer2} alt="" />
                            <div className={`absolute left-0 top-[79px] w-[433px] h-auto flex bg-white ${isHovered1 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.25')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.26')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px] relative'>
                        <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered2(true)}
                            onMouseLeave={() => setIsHovered2(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered2 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer3} alt="" />
                            <div className={`absolute left-0 top-[143px] w-[433px] h-auto flex bg-white ${isHovered2 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.27')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.28')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px] relative'>
                        <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered3(true)}
                            onMouseLeave={() => setIsHovered3(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered3 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer4} alt="" />
                            <div className={`absolute left-0 top-[97px] w-[433px] h-auto flex bg-white ${isHovered3 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.29')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.30')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                </div>
                <div className='w-[630px]'>
                    <Fade><div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered4(true)}
                            onMouseLeave={() => setIsHovered4(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered4 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer5} alt="" />
                            <div className={`absolute left-0 top-[154px] w-[433px] h-auto flex bg-white ${isHovered4 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.31')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.32')}</p>
                                </div>
                            </div>
                        </div></Fade>
                    <Fade delay={100}><div className='mt-[20px]'>
                         <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered5(true)}
                            onMouseLeave={() => setIsHovered5(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered5 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer6} alt="" />
                            <div className={`absolute left-0 top-[175px] w-[433px] h-auto flex bg-white ${isHovered5 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.33')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.34')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px]'>
                    <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered6(true)}
                            onMouseLeave={() => setIsHovered6(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered6 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer7} alt="" />
                            <div className={`absolute left-0 top-[150px] w-[433px] h-auto flex bg-white ${isHovered6 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.35')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.36')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade delay={100}><div className='mt-[20px]'>
                    <div
                            className="relative overflow-hidden"
                            onMouseEnter={() => setIsHovered7(true)}
                            onMouseLeave={() => setIsHovered7(false)}
                            >
                            <img className={`w-full h-full object-cover ${isHovered7 ? 'imgoffer zoom' : 'imgoffer'}`} src={whatoffer8} alt="" />
                            <div className={`absolute left-0 top-[150px] w-[433px] h-auto flex bg-white ${isHovered7 ? 'fade-in show' : 'fade-in'}`}>
                                <div className='mx-[40px]'>
                                <h1 className='inter text-[24px] font-medium mt-[40px]'>{t('home.a.1')}</h1>
                                <p className='inter textcolor mt-[8px] pb-[36px]'>{t('home.a.2')}</p>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                </div>
            </div>
            <div className='mt-[40px] block xl:hidden'>
                <Swiper
                    modules={[Navigation, Autoplay]}
                    slidesPerView={2}
                    centeredSlides={true}
                    loop={true}
                    // autoplay={{ delay: 3000, disableOnInteraction: false }}
                    className='mt-[40px]'
                    onSlideChange={(swiper) => {
                        setCurrentIndex(swiper.activeIndex); 
                    }}
                    onInit={(e) => {
                        setSwiper(e);
                      }}
                      breakpoints={{
                        1: {
                            slidesPerView: 1,
                          },
                        680: {
                          slidesPerView: 2,
                        },
                      }}
                >
                    <SwiperSlide key={currentLang}>
                        <div className="mx-[20px] xl:mx-0">
                            <div className='mx-auto h-[256px] overflow-hidden relative'>
                                <img src={whatoffer1} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.23')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.24')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mx-[20px] xl:mx-0">
                            <div className='mx-auto h-[256px] overflow-hidden relative'>
                                <img src={whatoffer2} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.25')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.26')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mx-[20px] xl:mx-0">
                            <div className='mx-auto h-[256px] overflow-hidden relative'>
                                <img src={whatoffer3} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.27')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.28')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mx-[20px] xl:mx-0">
                            <div className='mx-auto h-[256px] overflow-hidden relative'>
                                <img src={whatoffer4} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.29')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.30')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mx-[20px] xl:mx-0">
                            <div className='mx-auto h-[256px] overflow-hidden relative'>
                                <img src={whatoffer5} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.31')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.32')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mx-[20px] xl:mx-0">
                            <div className='mx-auto h-[256px] overflow-hidden relative'>
                                <img src={whatoffer6} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.33')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.34')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mx-[20px] xl:mx-0">
                            <div className='mx-auto h-[256px] overflow-hidden relative'>
                                <img src={whatoffer7} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.35')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.36')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mx-[20px] xl:mx-0">
                            <div className='mx-auto h-[256px] overflow-hidden relative'>
                                <img src={whatoffer8} alt="" className='absolute top-0 left-0 w-full h-full object-cover'/>
                            </div>
                            <h1 className='inter text-[16px] font-medium mt-[40px]'>{t('home.a.1')}</h1>
                            <p className='inter textcolor text-[12px] mt-[16px]'>{t('home.a.2')}</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    </>
  );
}

export default WhatOffer;
