import React from 'react';

import { useTranslation } from "react-i18next";

import p1 from '../../img/About/p1.webp'
import p2 from '../../img/About/p2.webp'

function OurPeople() {

  const { t } = useTranslation();

  return (
    <>
        <div className='relative mt-[100px] xl:mt-[160px] mx-[20px] max-w-[1280px] xl:mx-auto'>
            <div className='flex flex-col xl:flex-row gap-[20px] justify-between'>
                <div className='xl:w-[630px] h-[300px] xl:h-[480px] border px-[20px] xl:px-[40px] py-[20px] xl:py-[60px] flex flex-col xl:justify-end'>
                    <div className='flex items-center mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'>{t('about.12')}</h1>
                    </div>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px] xl:mt-[40px]'>{t('about.13')}</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px]'>{t('about.14')}</p>
                </div>
                <div className='xl:w-[630px] xl:h-[480px]'>
                    <img src={p1} alt="" className='w-full h-full object-cover'/>
                </div>
            </div>
            <div className='flex flex-col xl:flex-row-reverse justify-between mt-[20px] gap-[20px]'>
                <div className='xl:w-[630px] h-[300px] xl:h-[480px] border px-[20px] xl:px-[40px] py-[20px] xl:py-[60px] flex flex-col xl:justify-end'>
                    <div className='flex items-center mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'>{t('about.15')}</h1>
                    </div>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px] xl:mt-[40px]'>{t('about.16')}</p>
                </div>
                <div className='xl:w-[630px] xl:h-[480px]'>
                    <img src={p2} alt="" className='w-full h-full object-cover'/>
                </div>
            </div>
        </div>
    </>
  );
}

export default OurPeople;
