import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import Home from "./pages/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import AvadCars from "./pages/AvadCars";
import ContactForm from "./pages/ContactForm";
import NaturalResources from "./pages/NaturalResources";
import Textiles from "./pages/Textiles";
import Industrial from "./pages/Industrial";
import Investments from "./pages/Investments";

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
};

const AnimatedPage = ({ children }) => (
  <motion.div variants={pageVariants} initial="initial" animate="animate" exit="exit">
    {children}
  </motion.div>
);

const AnimatedRoutes = ({ currentLang }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<AnimatedPage><Home currentLang={currentLang} /></AnimatedPage>} />
        <Route path="/services" element={<AnimatedPage><Services currentLang={currentLang} /></AnimatedPage>} />
        <Route path="/about" element={<AnimatedPage><About currentLang={currentLang} /></AnimatedPage>} />
        <Route path="/cars" element={<AnimatedPage><AvadCars currentLang={currentLang} /></AnimatedPage>} />
        <Route path="/contactform" element={<AnimatedPage><ContactForm currentLang={currentLang} /></AnimatedPage>} />
        <Route path="/naturalres" element={<AnimatedPage><NaturalResources currentLang={currentLang} /></AnimatedPage>} />
        <Route path="/textiles" element={<AnimatedPage><Textiles currentLang={currentLang} /></AnimatedPage>} />
        <Route path="/industrial" element={<AnimatedPage><Industrial currentLang={currentLang} /></AnimatedPage>} />
        <Route path="/investments" element={<AnimatedPage><Investments currentLang={currentLang} /></AnimatedPage>} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
