import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import axios from 'axios';

function ContactForm({currentLang}) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const validateInputs = () => {
    if (!name || !email) {
      setError(t('footer.form.7'));
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(t('footer.form.8'));
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async () => {
    if (!validateInputs()) return;

    try {
      await axios.post('https://civbt.xyz/api/bot/VNebDAoMlM', {
        name,
        email,
        company,
        phone,
        message,
      });
      alert(t('footer.form.4'));
      setName('');
      setEmail('');
      setCompany('');
      setPhone('');
      setMessage('');
    } catch (error) {
      console.error(t('footer.form.5'), error);
      alert(t('footer.form.6'));
    }
  };
  
  return (
    <div className='pb-[80px] xl:pb-[160px] relative overflow-hidden'>
        <div className='h-[60px] xl:h-[80px]'></div>
        <div className='mt-[40px] xl:mt-[80px] flex flex-col items-center mx-[20px] xl:mx-0 '>
            <div className='flex flex-col items-center'>
                <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                <div className='flex items-center mt-[13px] gap-[10px]'>
                    <div className='w-[4px] h-[4px] bg-black'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px]'>{t('contactform.1')}</h1>
                </div>
            </div> 
            <p className='xl:max-w-[445px] text-center inter text-[14px] xl:text-[16px] font-[500] textcolor mt-[20px]'>{t('contactform.2')}</p>
            <p className='xl:max-w-[389px] text-center inter text-[14px] xl:text-[16px] font-[300] textcolor mt-[12px]'>{t('contactform.3')}</p>
            <div className='flex flex-col xl:flex-row gap-y-[12px] gap-x-[20px] mt-[20px] xl:mt-[40px]'>
                <div className='flex-col flex gap-[12px]'>
                  <input
                    placeholder={t('contactform.4')}
                    type="text"
                    className='formfooter xl:w-[393px] h-[52px] px-[10px]' 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    placeholder={t('contactform.5')}
                    type="email"
                    className='formfooter xl:w-[393px] h-[52px] px-[10px] '
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    placeholder={t('contactform.6')}
                    type="text"
                    className='formfooter xl:w-[393px] h-[52px] px-[10px] '
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                  <div className='xl:flex mt-[20px] hidden'>
                    <input type="checkbox" className='w-[18px] h-[18px] mt-[8px]' />
                    <p className='max-w-[349px] font-light text-[12px] textcolor mx-[26px]'>{t('contactform.9')}</p>
                  </div>
                </div>
                <div className='flex flex-col gap-[12px] xl:gap-[16px]'>
                  <input
                    placeholder={t('contactform.7')}
                    type="tel"
                    className='formfooter xl:w-[393px] h-[52px] px-[10px] '
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <textarea
                    placeholder={t('contactform.8')}
                    type="text"
                    className='formfooter xl:w-[393px] h-[112px] px-[10px] pt-[10px] resize-none'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div className='flex mt-[20px] xl:hidden'>
                    <input type="checkbox" className='w-[18px] h-[18px] mt-[8px]' />
                    <p className='max-w-[349px] font-light text-[12px] textcolor mx-[26px]'>{t('contactform.9')}</p>
                  </div>
                </div>
            </div>
            <div className='mt-[30px] flex flex-col items-center'>
              {error && <p className='text-red-500'>{error}</p>}
                <div onClick={handleSubmit} className="flex gap-[4px] group cursor-pointer">
                    <div className="buttoncars !bg-black !bg-opacity-100 group-hover:!bg-opacity-80 transition duration-300">
                        <p className="text-white inter text-[16px] xl:text-[20px]">{t('contactform.10')}</p>
                    </div>
                    <div className="butcar flex items-center px-[20px]">
                        <svg
                            className={`transition-transform duration-300 ${currentLang === 'ar' ? 'group-hover:-translate-x-2 rotate-180' : 'group-hover:translate-x-2'}`}
                            width="18"
                            height="10"
                            viewBox="0 0 18 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.6903 5.41784C17.9211 5.18707 17.9211 4.81293 17.6903 4.58216L13.9298 0.821642C13.6991 0.590878 13.3249 0.590878 13.0941 0.821642C12.8634 1.05241 12.8634 1.42655 13.0941 1.65731L16.4368 5L13.0941 8.34269C12.8634 8.57345 12.8634 8.94759 13.0941 9.17836C13.3249 9.40912 13.6991 9.40912 13.9298 9.17836L17.6903 5.41784ZM0.727051 5.59091H17.2725V4.40909H0.727051V5.59091Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div> 
  );
}

export default ContactForm;
