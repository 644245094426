import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

import img from '../../img/AvadCars/img.webp'
import imgmob from '../../img/AvadCars/imgm.webp'

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import button from '../../img/button.svg'

import check from '../../img/AvadCars/check.svg'

function Main({currentLang}) {

  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);

  const [swiper, setSwiper] = useState({});

          const [reloadKey, setReloadKey] = useState(0);
      
          useEffect(() => {
              setReloadKey(prev => prev + 1); // Обновляем ключ каждый раз при смене языка
          }, [currentLang]);

  return (
    <>
        <div className='h-[60px] xl:h-[80px]'></div>
        <div className='max-w-[1280px] mx-auto md:h-[306px] xl:h-[506px] relative overflow-hidden'>
            <img src={img} alt="" className='hidden md:block absolute w-full h-full object-cover'/>
            <div className={`md:w-[517px] bg-[#FFFFFFCC] h-full absolute hidden md:block ${currentLang === 'ar' ? 'right-0' : 'left-0'}`}></div>
            <div className='max-w-[1280px] mx-[20px] xl:mx-[40px] relative mt-[40px] md:mt-[75px] xl:mt-[120px]'>
                <p className='text-[24px] xl:text-[48px] neutral xl:max-w-[411px]'><Trans>{t('cars.1')}</Trans></p>
                <p className='md:max-w-[392px] inter text-[16px] xl:text-[20px] font-[400] text-[#000] mt-[20px]'><Trans>{t('cars.2')}</Trans></p>
                <a href="https://blog.devindex.net/">
                    <div className="flex gap-[4px] group mt-[40px]">
                        <div className="buttoncars !bg-black !bg-opacity-100 group-hover:!bg-opacity-80 transition duration-300">
                            <p className="text-white inter text-[16px] xl:text-[20px]">{t('cars.3')}</p>
                        </div>
                        <div className="butcar flex items-center px-[20px]">
                            <svg
                                className={`transition-transform duration-300 ${currentLang === 'ar' ? 'group-hover:-translate-x-2 rotate-180' : 'group-hover:translate-x-2'}`}
                                width="18"
                                height="10"
                                viewBox="0 0 18 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.6903 5.41784C17.9211 5.18707 17.9211 4.81293 17.6903 4.58216L13.9298 0.821642C13.6991 0.590878 13.3249 0.590878 13.0941 0.821642C12.8634 1.05241 12.8634 1.42655 13.0941 1.65731L16.4368 5L13.0941 8.34269C12.8634 8.57345 12.8634 8.94759 13.0941 9.17836C13.3249 9.40912 13.6991 9.40912 13.9298 9.17836L17.6903 5.41784ZM0.727051 5.59091H17.2725V4.40909H0.727051V5.59091Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                </a>
                <img src={imgmob} alt="" className='md:hidden block w-full mt-[40px] object-cover h-[280px]'/>
            </div>
        </div>
        
        <div className='max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]' key={reloadKey}>
            <div className='block xl:hidden'>
                <Swiper
                    key={reloadKey}
                    slidesPerView={1}
                    className=''
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    onSlideChange={(swiper) => {
                        setCurrentIndex(swiper.activeIndex); 
                    }}
                    breakpoints={{
                        1: {
                            slidesPerView: 1,
                        },
                        680: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    <SwiperSlide>
                        <div className='flex items-start gap-[20px] border-b pb-[10px]'>
                            <img src={check} className={`flex-shrink-0 mt-[4px] w-[20px] xl:w-[40px]`} alt="" /> 
                            <div>
                                <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('cars.5')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.6')}</p>
                            </div>
                        </div>
                        <div className='flex items-start gap-[20px] border-b py-[10px]'>
                            <img src={check} className={`flex-shrink-0 mt-[4px] w-[20px] xl:w-[40px]`} alt="" />
                            <div>
                                <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('cars.7')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.8')}</p>
                            </div>
                        </div>
                        <div className='flex items-start gap-[20px] border-b py-[10px]'>
                            <img src={check} className={`flex-shrink-0 mt-[4px] w-[20px] xl:w-[34px]`} alt="" />
                            <div>
                                <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('cars.9')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.10')}</p>
                            </div>
                        </div>
                        <div className='flex items-start gap-[20px] border-b py-[10px]'>
                            <img src={check} className={`flex-shrink-0 mt-[4px] w-[20px] xl:w-[34px]`} alt="" />
                            <div>
                                <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('cars.11')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.12')}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='flex items-start gap-[20px] border-b pb-[10px]'>
                            <img src={check} className={`flex-shrink-0 mt-[4px] w-[20px] xl:w-[34px]`} alt="" />
                            <div>
                                <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('cars.13')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.14')}</p>
                            </div>
                        </div>
                        <div className='flex items-start gap-[20px] border-b py-[10px]'>
                            <img src={check} className={`flex-shrink-0 mt-[4px] w-[20px] xl:w-[34px]`} alt="" />
                            <div>
                                <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('cars.15')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.16')}</p>
                            </div>
                        </div>
                        <div className='flex items-start gap-[20px] border-b py-[10px]'>
                            <img src={check} className={`flex-shrink-0 mt-[4px] w-[20px] xl:w-[34px]`} alt="" />
                            <div>
                                <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('cars.17')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.18')}</p>
                            </div>
                        </div>
                        <div className='flex items-start gap-[20px] border-b py-[10px]'>
                            <img src={check} className={`flex-shrink-0 mt-[4px] w-[20px] xl:w-[34px]`} alt="" />
                            <div>
                                <p className='inter text-[16px] xl:text-[20px] font-[500]'>{t('cars.25')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.26')}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className={`flex justify-between mt-[20px] ${currentLang === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180'
                    />
                    <div>
                        <p className='inter font-[300] border rounded-[20px] py-[3px] px-[6px]'>{`0${currentIndex + 1}`} <span className='text-[#00000033]'>{`- 02`}</span></p>
                    </div>
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px]'
                    />
                </div>   
            </div>
            <div className='xl:flex justify-between hidden'>
                <div className='w-[600px]'>
                    <div className='flex items-start gap-[70px] border-b py-[30px] h-[140px]'>
                        <img src={check} className={`flex-shrink-0 mt-[10px] w-[20px] xl:w-[34px]`} alt="" />
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.5')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.6')}</p>
                        </div>
                    </div>
                    <div className='flex items-start gap-[70px] border-b py-[30px] h-[140px]'>
                        <img src={check} className={`flex-shrink-0 mt-[10px] w-[20px] xl:w-[34px]`} alt="" />
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.7')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.8')}</p>
                        </div>
                    </div>
                    <div className='flex items-start gap-[70px] border-b py-[30px] h-[140px]'>
                        <img src={check} className={`flex-shrink-0 mt-[10px] w-[20px] xl:w-[34px]`} alt="" />
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.9')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.10')}</p>
                        </div>
                    </div>
                    <div className='flex items-start gap-[70px] py-[30px] h-[140px]'>
                        <img src={check} className={`flex-shrink-0 mt-[10px] w-[20px] xl:w-[34px]`} alt="" />
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.11')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.12')}</p>
                        </div>
                    </div>                   
                </div>
                <div className='w-[600px]'>
                    <div className='flex items-start gap-[70px] border-b py-[30px] h-[140px]'>
                        <img src={check} className={`flex-shrink-0 mt-[10px] w-[20px] xl:w-[34px]`} alt="" />
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.13')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.14')}</p>
                        </div>
                    </div>
                    <div className='flex items-start gap-[70px] border-b py-[30px] h-[140px]'>
                        <img src={check} className={`flex-shrink-0 mt-[10px] w-[20px] xl:w-[34px]`} alt="" />
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.15')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.16')}</p>
                        </div>
                    </div>
                    <div className='flex items-start gap-[70px] border-b py-[30px] h-[140px]'>
                        <img src={check} className={`flex-shrink-0 mt-[10px] w-[20px] xl:w-[34px]`} alt="" />
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.17')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.18')}</p>
                        </div>
                    </div>
                    <div className='flex items-start gap-[70px] py-[30px] h-[140px]'>
                        <img src={check} className={`flex-shrink-0 mt-[10px] w-[20px] xl:w-[34px]`} alt="" />
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.25')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.26')}</p>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    </>
  );
}

export default Main;
