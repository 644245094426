import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Link, useLocation } from "react-router-dom";
import logo from '../img/avad.svg';
import i18n from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import MobileMenu from "./MobileMenu";

import en from '../img/us.png';
import ar from '../img/ar.png';
import tr from '../img/tr.png';

function Header({ currentLang, storedLang, setCurrentLang }) {
  const { t } = useTranslation();
  const location = useLocation();

  const languages = [
    {
      value: 'en',
      label: 'EN',
      img: en,
    },
    {
      value: 'ar',
      label: 'AR',
      img: ar,
    },
    {
      value: 'tr',
      label: 'TR',
      img: tr,
    },
  ];

  useEffect(() => {
    if (storedLang && storedLang !== currentLang) {
      setCurrentLang(storedLang);
      i18n.changeLanguage(storedLang);
    }
    document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
  }, [storedLang]);

  const handleChangeLang = (selectedLang) => {
    $('#loader').removeClass('hide'); // Показываем лоадер

    setTimeout(() => {
      i18n.changeLanguage(selectedLang.value); // Меняем язык
      setCurrentLang(selectedLang.value);
      Cookies.set("i18next", selectedLang.value);
    }, 1000); // Задержка для анимации лоадера (500 мс)
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLang, setIsOpenLang] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [isScrolledPastHeight, setIsScrolledPastHeight] = useState(true);
  const [MainMenu, setMainMenu] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/") {
      setIsScrolledPastHeight(true); // На всех страницах кроме главной — фон всегда белый
      setMainMenu(true);
    } else {
      setMainMenu(false);

      const handleScroll = () => {
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        setIsScrolledPastHeight(!(scrollY + 100 >= windowHeight));
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [location.pathname]);

  // Закрываем выпадающее меню при клике вне его
  useEffect(() => {
    const handleClickOutside = (event) => {
      const menuElement = document.getElementById('languageMenu');
      if (menuElement && !menuElement.contains(event.target)) {
        setIsOpenLang(false); // Закрываем меню
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header>
        <div className={`h-[60px] md:h-[80px] fixed z-[999] w-full transition-all bg-white`}>
          <div className={`max-w-[1280px] mx-[20px] h-[60px] md:h-[80px] xl:mx-auto flex items-center justify-between`}>
            <Link to="/"><img src={logo} alt="" className={`w-[100px] z-[500] relative transition-all duration-500 invert`}/></Link>
            <div className="flex items-center gap-[20px] xl:gap-[20px]">
              <div className="relative" id="languageMenu">
                <div
                  className={`flex items-center gap-[7px] w-[75px] rounded-[20px] cursor-pointer border py-[2px] px-[5px] ${isScrolledPastHeight ? '' : 'bg-white'}`}
                  onClick={() => setIsOpenLang(!isOpenLang)}
                >
                  <img src={languages.find(lang => lang.value === currentLang)?.img} alt={currentLang} className="w-[30px] h-[30px]" />
                  <svg className={` ${isOpenLang ? 'rotate-180 transition-all' : 'transition-all'}`} xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none">
                    <path d="M1 1L6.66667 6L12 1" stroke='black' stroke-opacity='0.7' stroke-width='1.2'/>
                  </svg>
                </div>

                {isOpenLang && (
                  <div className="absolute top-full mt-2 w-[75px] bg-white shadow-lg rounded-[10px] border">
                    {languages.map((lang) => (
                      <div
                        key={lang.value}
                        className="flex items-center gap-[4px] py-[8px] px-[5px] cursor-pointer rounded-[10px] hover:bg-gray-200"
                        onClick={() => handleChangeLang(lang) || setIsOpenLang(false)}
                      >
                        <img src={lang.img} alt={lang.label} className="w-[30px] h-[30px]" />
                        <span className="text-[#000000B2] inter">{lang.label}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <MobileMenu currentLang={currentLang} isScrolledPastHeight={isScrolledPastHeight} MainMenu={MainMenu} toggleMenu={toggleMenu} isOpen={isOpen}/>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
