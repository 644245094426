import React, { useEffect } from 'react';

import Main from '../components/About/Main'
import AboutUSUS from '../components/About/AboutUSUS';
import OurValues from '../components/Home/OurValues';
import OurPeople from '../components/About/OurPeople';
import FeedBack from '../components/Home/FeedBack';
import MeetOurTeam from '../components/About/MeetOurTeam';

function About({currentLang}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='relative overflow-hidden'>
      <Main currentLang={currentLang}/>
      <AboutUSUS/>
      {/* <OurValues/> */}
      <OurPeople/>
      <MeetOurTeam currentLang={currentLang}/>
      <FeedBack currentLang={currentLang}/>
    </div>
  );
}

export default About;
