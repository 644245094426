import React from 'react';

import Fade from 'react-reveal/Fade'

import i1 from '../../img/Home/image.webp'
import i2 from '../../img/Home/image1.webp'

import { useTranslation } from "react-i18next";

function Mission() {

  const { t } = useTranslation();

  return (
    <>
      <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto'>
        <div className='flex xl:flex-row flex-col justify-between'>
            <div>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                    <div className='flex items-center mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'>{t('new.home.4')}</h1>
                    </div>
                </div>
                <p className='inter text-[14px] xl:text-[16px] font-light mt-[20px] xl:mt-[40px] xl:max-w-[457px] textcolor'>{t('new.home.5')}</p>
            </div>
            <Fade right><img src={i1} alt="" className='mx-auto xl:mx-0 mt-[20px] xl:mt-0 h-[325px] object-cover w-full xl:w-auto'/></Fade>
        </div>
        <div className='flex xl:flex-row-reverse flex-col justify-between items-end mt-[20px]'>
            <div>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                    <div className='flex items-center mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'>{t('new.home.6')}</h1>
                    </div>
                </div>
                <p className='inter text-[14px] xl:text-[16px] font-light mt-[20px] xl:mt-[40px] xl:max-w-[473px] textcolor'>{t('new.home.7')}</p>
            </div>
            <Fade left><img src={i2} alt="" className='mx-auto xl:mx-0 mt-[20px] xl:mt-0 h-[325px] object-cover w-full xl:w-auto'/></Fade>
        </div>
      </div>
    </>
  );
}

export default Mission;
