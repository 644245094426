import React, { useState, useEffect } from 'react';

import { useTranslation, Trans } from "react-i18next";

import img1 from '../../img/AvadCars/image(1).webp'
import img2 from '../../img/AvadCars/image(2).webp'
import img3 from '../../img/AvadCars/image(3).webp'
import img4 from '../../img/AvadCars/image(4).webp'
import img5 from '../../img/AvadCars/image(5).webp'
import img6 from '../../img/AvadCars/image(6).webp'
import img7 from '../../img/AvadCars/image(7).webp'
import img8 from '../../img/AvadCars/image(8).webp'
import img9 from '../../img/AvadCars/image(9).webp'

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

import button from '../../img/button.svg'

function WeOffer({currentLang}) {

  const { t } = useTranslation();

  const [swiper, setSwiper] = useState({});

    const [reloadKey, setReloadKey] = useState(0);
  
      useEffect(() => {
          setReloadKey(prev => prev + 1); // Обновляем ключ каждый раз при смене языка
      }, [currentLang]);

  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[100px] xl:mt-[160px] pb-[100px] xl:pb-[160px] relative flex flex-col' key={reloadKey}>
                <div className='flex justify-between mx-[20px] xl:mx-0 items-center'>
                  <div>
                      <div className='bg-black h-[1px]'></div>
                      <div className='flex items-start mt-[13px] gap-[10px]'>
                          <div className='w-[4px] h-[4px] bg-black mt-[10px] xl:mt-[15px]'></div>
                          <h1 className='neutral text-[16px] xl:text-[24px]'><Trans>{t('cars.23')}</Trans></h1>
                      </div>
                  </div> 
                  <div className={`flex gap-[20px] xl:hidden ${currentLang === 'ar' ? 'flex-row-reverse' : 'flex-row mr-[20px]'} w-[60px]`}>
                      <img
                      onClick={() => swiper.slidePrev()}
                      src={button}
                      className='w-[30px] h-[30px] rotate-180 cursor-pointer'
                      />
                      <img
                      onClick={() => swiper.slideNext()}
                      src={button}
                      className='w-[30px] h-[30px] cursor-pointer'
                      />
                  </div>               
              </div> 
            <div className='xl:flex flex-wrap justify-between mt-[60px] hidden'>
                <div className='relative overflow-hidden'>
                    <img src={img1} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>01</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Ferrari</p>
                </div>
                <div className='relative overflow-hidden'>
                    <img src={img2} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>02</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Lamborghini</p>
                </div>
                <div className='relative overflow-hidden'>
                    <img src={img3} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>03</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>McLaren</p>
                </div>
                <div className='relative overflow-hidden mt-[50px]'>
                    <img src={img4} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>04</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Porsche</p>
                </div>
                <div className='relative overflow-hidden mt-[50px]'>
                    <img src={img5} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>05</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Rolls-Royce</p>
                </div>
                <div className='relative overflow-hidden mt-[50px]'>
                    <img src={img6} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>06</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Mercedes-Benz</p>
                </div>
                <div className='relative overflow-hidden mt-[50px]'>
                    <img src={img7} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>07</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Audi</p>
                </div>
                <div className='relative overflow-hidden mt-[50px]'>
                    <img src={img8} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>08</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>BMW</p>
                </div>
                <div className='relative overflow-hidden mt-[50px]'>
                    <img src={img9} alt="" className='hover:scale-110 transition-all duration-500'/>
                    {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>09</p> */}
                    <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Lexus</p>
                </div>
            </div>
            <div className='block xl:hidden mx-[20px] mt-[40px]'>
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    className='h-[290px]'
                    breakpoints={{
                        1: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                                <img src={img1} alt="" className='w-full h-full object-cover'/>
                                {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>01</p> */}
                                <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Ferrari</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                            <img src={img2} alt="" className='w-full h-full object-cover'/>
                            {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>02</p> */}
                            <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Lamborghini</p>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                            <img src={img3} alt="" className='w-full h-full object-cover'/>
                            {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>03</p> */}
                            <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>McLaren</p>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                            <img src={img4} alt="" className='w-full h-full object-cover'/>
                            {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>04</p> */}
                            <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Porsche</p>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                            <img src={img5} alt="" className='w-full h-full object-cover'/>
                            {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>05</p> */}
                            <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Rolls-Royce</p>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                            <img src={img6} alt="" className='w-full h-full object-cover'/>
                            {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>06</p> */}
                            <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Mercedes-Benz</p>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                            <img src={img7} alt="" className='w-full h-full object-cover'/>
                            {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>07</p> */}
                            <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Audi</p>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                            <img src={img8} alt="" className='w-full h-full object-cover'/>
                            {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>08</p> */}
                            <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>BMW</p>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='relative overflow-hidden w-full flex flex-col'>
                            <div className='relative mx-auto w-full h-[290px]'>         
                            <img src={img9} alt="" className='w-full h-full object-cover'/>
                            {/* <p className='text-white top-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>09</p> */}
                            <p className='text-white bottom-[20px] left-[40px] absolute inter text-[16px] xl:text-[20px] font-[500]'>Lexus</p>
                        </div>
                    </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='flex items-center mt-[20px] xl:mt-[40px] justify-between sm:justify-end mx-[20px] sm:gap-[50px] xl:mx-0'>
                <p className='text-[16px] xl:text-[24px] uppercase neutral'>{t('cars.24')}</p>
                <a href="https://blog.devindex.net/">
                    <div className="flex gap-[4px] group">
                        <div className="buttoncars !bg-black !bg-opacity-100 group-hover:!bg-opacity-80 transition duration-300">
                            <p className="text-white inter text-[16px] xl:text-[20px]">{t('cars.3')}</p>
                        </div>
                        <div className="butcar flex items-center px-[20px]">
                            <svg
                                className={`transition-transform duration-300 ${currentLang === 'ar' ? 'group-hover:-translate-x-2 rotate-180' : 'group-hover:translate-x-2'}`}
                                width="18"
                                height="10"
                                viewBox="0 0 18 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.6903 5.41784C17.9211 5.18707 17.9211 4.81293 17.6903 4.58216L13.9298 0.821642C13.6991 0.590878 13.3249 0.590878 13.0941 0.821642C12.8634 1.05241 12.8634 1.42655 13.0941 1.65731L16.4368 5L13.0941 8.34269C12.8634 8.57345 12.8634 8.94759 13.0941 9.17836C13.3249 9.40912 13.6991 9.40912 13.9298 9.17836L17.6903 5.41784ZM0.727051 5.59091H17.2725V4.40909H0.727051V5.59091Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                </a>
            </div>
           
        </div>
    </>
  );
}

export default WeOffer;
