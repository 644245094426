import React from 'react';

import global from '../../img/Services/global.webp'
import global2 from '../../img/Services/global2.webp'

import { useTranslation } from "react-i18next";

function Global({currentLang}) {

  const { t } = useTranslation();

  return (
    <>
        <div className='relative mt-[100px] xl:mt-[160px]'>
            <div className='max-w-[1280px] mx-auto md:h-[306px] xl:h-[506px] relative overflow-hidden mt-[100px] xl:mt-[160px]'>
                <img src={global} alt="" className='hidden md:block absolute w-full h-full object-cover'/>
                <div className={`md:w-[517px] bg-[#FFFFFFCC] h-full absolute hidden md:block ${currentLang === 'ar' ? 'right-0' : 'left-0'}`}></div>
                <div className='max-w-[1280px] mx-[20px] xl:mx-[40px] relative sm:mt-[20px] xl:mt-[80px]'>
                    <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                    <div className='flex items-center mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'>{t('services.38')}</h1>
                    </div>
                    </div>
                    <p className='md:max-w-[392px] inter text-[14px] xl:text-[16px] font-[400] text-[#000000B2] mt-[20px] xl:mt-[40px]'>{t('services.39')}</p>
                    <p className='md:max-w-[392px] inter text-[14px] xl:text-[16px] font-[400] text-[#000000B2] mt-[20px]'>{t('services.40')}</p>
                    <img src={global2} alt="" className='md:hidden block w-full mt-[40px] object-cover h-[280px]'/>
                </div>
            </div>
        </div>
    </>
  );
}

export default Global;
