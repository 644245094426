import React from 'react';

import Fade from 'react-reveal/Fade'

import prev from '../../img/Home/img.webp'

import { useTranslation } from "react-i18next";

function Video() {

  const { t } = useTranslation();

  return (
    <>
      <div id="video-block-home" className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto'>
        <video src="https://avadgate.com/avad-video.mp4" autoPlay muted controls loop playsInline poster={prev}></video>
      </div>
    </>
  );
}

export default Video;
